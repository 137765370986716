/* General styles for the button */
.btn-secondary {
  background-color: transparent;
  color: #333;
  padding: 0.75rem 0.5rem;
  border: 2px solid #333;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  font-family: Arial, sans-serif;
}

.modal-content h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Form input */
.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

/* Checkbox container */
.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #333;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Submit button */
.submit-btn {
  background-color: #1e3a8a;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

/* Close button */
.close-btn {
  background-color: #f44336;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 0.9rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    padding: 20px;
  }

  .modal-content h2 {
    font-size: 1.3rem;
  }
}
