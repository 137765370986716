.Nav {
  justify-content: center;
  text-align: center;
  background-color: #fffff8;
  padding: 30px 170px 30px 170px;
}
.NavbarWrapper {
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}
.NavLogo {
  float: left;
  font-size: 25px;
  font-weight: 900;
  margin: -8px 2px 1px 15px;
  transition: 0.2s ease-in;
}
.NavElements {
  display: inline;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 200;
  padding: 10px 20px 10px 20px;
}
.drop {
  display: inline;
  position: relative;
  padding-top: 8px;
  font-size: 1rem;
  font-weight: 200;
  color: black;
}
.NavButton {
  float: right;
  border-radius: 20px;
  border: 1px solid black;
  padding: 10px 20px 10px 20px;
  margin: -8px 2px 1px 2px;
}
a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

a:hover,
.drop:hover {
  cursor: pointer;
  color: #e93a7d;
  border-radius: 20px;
  transition: 0.5ms;
}
/* .active {
  cursor: pointer;
  color: #e93a7d;
  border-radius: 20px;
  transition: 0.5ms;
} */
.Icon {
  display: none;
  font-size: 25px;
  cursor: pointer;
  color: black;
}
@media screen and (max-width: 925px) {
  .Nav {
    padding: 25px 8px 25px 1rem;
  }
  .NavElements {
    display: none;
  }
  .NavButton {
    display: none;
  }
  .Icon {
    display: inline;
    position: relative;
    left: 35%;
  }
  .NavLogo {
    margin: 0;
    transition: 0.2s ease-in-out;
  }
}
