.content-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  /* margin: 2rem 0rem; */
  gap: 5rem;
  flex-wrap: wrap;
  max-width: 800px;
}

.text-section {
  flex: 1;
}

.label {
  display: inline;
  font-size: 14px;
  color: #666;
  padding: 0.2rem;
  background-color: #b0b0b0;
  border-radius: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  max-width: 300px;
  margin: 10px 0 10px;
}

.description {
  font-size: 16px;
  color: #666;
  max-width: 300px;
}

.image-section {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
  border: 4px solid #ad5e50; /* Green border */
  border-bottom: 0px;
}

.image-section img {
  width: 100%;
  /* height: 40vh; */
  display: block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-card {
    flex-direction: column;
    text-align: center;
    gap: 3rem;
  }

  .text-section {
    padding: 0;
  }
  .image-section img {
    width: 100%;
    /* height: 37vh; */
    display: block;
  }
}
