.card-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.card {
  border: 2px solid;
  padding: 20px;
  text-align: start;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px; /* Adjust width to fit nicely in rows */
}

.card h2 {
  font-size: 2rem;
  margin: 0;
}

.card p {
  font-size: 1rem;
  margin: 10px 0 0;
}
.faq-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.faq-questions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.faq-question {
  background-color: #ffe6d6;
  padding: 20px;
  border: none;
  border-radius: 8px;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
  color: #333;
}

.faq-question.active {
  /* background-color: #d11d5b; */
  background-color: #ad5e50;
  color: white;
}

.faq-answer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  min-width: 250px;
  max-width: 350px;
  padding: 20px;
  padding-top: 4rem;
  height: 50vh;
  margin-top: 3.5rem;
  background-color: #ad5e50;
  color: white;
  border-radius: 8px;
}

.faq-answer h3 {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .faq-container {
    flex-direction: column;
    align-items: center;
  }

  .faq-answer {
    margin-top: 0rem;
    padding-top: 2rem;
  }
}
