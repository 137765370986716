/* Container styling */
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  margin-top: 5rem;
  max-width: 100%;
  /* margin: 0 2rem; */
}

/* Image container styling */
.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 400px;
}

.profile-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Decorative lines on top and left of the image */
/* .image-container::before,
.image-container::after {
  content: "";
  position: absolute;
  background-color: #2a65f2; 
} */

/* .image-container::before {
  top: -8px;
  left: 0;
  height: 4px;
  width: 100%;
}

.image-container::after {
  top: 0;
  right: -8px;
  width: 4px;
  height: 100%;
} */

/* Text container styling with responsive font sizes */
.text-container {
  flex: 2;
  max-width: 400px;
}

.text-container h5 {
  font-size: clamp(0.9rem, 1vw + 0.5rem, 1rem); /* Responsive */
  color: #555;
  margin-bottom: 0.5rem;
}

.text-container h1 {
  font-size: clamp(1.5rem, 2vw + 1rem, 2.5rem); /* Responsive */
  margin: 0;
  color: #333;
}

.text-container p {
  font-size: clamp(0.9rem, 1vw + 0.4rem, 1.1rem); /* Responsive */
  line-height: 1.5;
  color: #666;
  margin-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .image-container,
  .text-container {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .text-container h1 {
    font-size: clamp(
      1.2rem,
      3vw + 0.8rem,
      1.5rem
    ); /* Further scaling for smaller screens */
  }

  .text-container p {
    font-size: clamp(
      0.8rem,
      2vw + 0.3rem,
      1rem
    ); /* Further scaling for smaller screens */
  }
}
