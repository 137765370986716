.Navbars {
  background-color: #fffff8;
  padding-bottom: 20px;
  position: absolute;
  width: 100%;
  transition: 0.2s ease-in-out;
}
@media screen and (min-width: 920px) {
  .Navbars {
    display: none;
  }
}
.NavbarWrappers {
  list-style-type: none;
}

.NavbarElement {
  padding: 10px;
  justify-content: center;
  text-align: center;
}

.link {
  text-decoration: none;
  color: black;
}
.link:hover {
  color: #e93a7d;
  padding: 10px;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.active {
  color: black;
}
.linkbtn {
  text-decoration: none;
  color: black;
  border: 1px solid black;
  padding: 10px;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.linkbtn:hover {
  color: #e93a7d;
}

.Navbutton {
  margin-top: 50px;
  text-align: center;
  padding: 10px;
}
.drop {
  display: inline;
  position: relative;
  padding-top: 8px;
  font-size: 1rem;
  font-weight: 200;
  color: black;
}
.drop:hover {
  color: #e93a7d;
}
