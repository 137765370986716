/* Footer Container */
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #faf9f6; */
  padding: 20px;
  text-align: center;
  color: #333;
  font-family: Arial, sans-serif;
}

/* Logo Section */
.footer-logo {
  margin-bottom: 20px;
  text-align: center;
}

.footer-logo h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.footer-logo p {
  font-size: 0.9rem;
  margin: 0;
}

/* Navigation Links */
.footer-nav {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.footer-nav a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-nav a:hover {
  color: #ff0000; /* Change color on hover */
}

/* Social Icons */
.footer-social {
  display: flex;
  gap: 15px;
  margin: 10px 0;
}

.social-icon {
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #ff0000;
}

/* Copyright */
.footer-copyright {
  border-top: 1px solid black;
  margin-top: 20px;
  font-size: 0.9rem;
  color: black;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-nav {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .footer-logo h1 {
    font-size: 1.5rem;
  }

  .footer-nav a {
    font-size: 0.9rem;
  }

  .social-icon {
    font-size: 1.2rem;
  }
}
