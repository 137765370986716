/* SkincarePromo.css */
.promo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 2rem; */
  padding: 30px 0rem 30px 5rem;
  /* background-color: #f8f3eb; */
  color: #333;
  margin: 0rem 1rem;
  margin-top: 1rem;
}

.promo-content {
  max-width: 650px;
  margin-right: 1rem;
  text-align: center;
}

.promo-content h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.promo-content p {
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.btn-primary {
  background-color: #e91e63;
  color: #fff;
  /* background-color: #ede0d4; */
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
}

.btn-secondary {
  background-color: transparent;
  color: #333;
  padding: 0.75rem 0.5rem;
  border: 2px solid #333;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .promo-container {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }

  .promo-content {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .promo-content h1 {
    font-size: 2rem;
  }

  .buttons {
    flex-direction: column;
  }
}
.info-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.info-card {
  flex: 1 1 300px;
  max-width: 400px;
  padding: 20px;
  border-radius: 12px;
  color: white;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.info-card p {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.discover-link {
  color: white;
  font-weight: bold;
  text-decoration: none;
  font-size: 1em;
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
}

@media (max-width: 768px) {
  .info-card {
    max-width: 100%;
  }
}
