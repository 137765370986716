.content-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  /* margin: 2rem 0rem; */
  gap: 5rem;
  flex-wrap: wrap;
  max-width: 800px;
}

.text-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 300px;
  border-radius: 10px;
  height: 45vh;
  padding: 0rem 0.5rem;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  max-width: 300px;
  margin: 10px 0 10px;
}

.description {
  font-size: 16px;
  color: #666;
  max-width: 300px;
}

.image-section1 {
  flex: 1;
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
  /* border: 8px solid #00c853; 
    border-bottom: 0px; */
}

.image-section1 img {
  width: 100%;
  height: 45vh;
  display: block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-card {
    flex-direction: column;
    text-align: center;
    gap: 3rem;
  }

  /* .text-section {
    padding: 0;
  } */
  .image-section1 img {
    width: 100%;
    height: 26vh;
    display: block;
  }
}
