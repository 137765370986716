.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.testimonial-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

#profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid gray;
  object-fit: cover;
  margin-bottom: 15px;
}

.testimonial-card h3 {
  font-size: 1.2em;
  margin: 10px 0;
}

.testimonial-card p {
  font-size: 0.95em;
  color: #555;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .testimonial-card {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .testimonials-container {
    flex-direction: column;
    align-items: center;
  }
}

.social-media-icons {
  display: flex;
  gap: 15px;
  padding-top: 20px;
}

.icon {
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #ff0000; /* Change color on hover */
}

/* Responsive styling */
@media (max-width: 600px) {
  .social-media-icons {
    gap: 10px;
  }

  .icon {
    font-size: 1.2rem;
  }
}

/* Container styling */
.faq-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 2.2rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

/* FAQ card styling */
.faq-card1 {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 50px 20px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  text-align: left;
  transition: box-shadow 0.3s ease;
}

/* Question and Answer styles */
.faq-question1 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.faq-answer1 {
  font-size: 1rem;
  color: #555;
}

/* Hover effect for FAQ card */
.faq-card1:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* Responsive design */
@media (max-width: 768px) {
  .faq-container1 {
    padding: 10px;
  }

  .faq-card1 {
    padding: 15px;
  }

  .faq-question1 {
    font-size: 1.1rem;
  }

  .faq-answer1 {
    font-size: 0.95rem;
  }
  .social-media-icons {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .faq-card1 {
    padding: 10px;
  }

  .faq-question1 {
    font-size: 1rem;
  }

  .faq-answer1 {
    font-size: 0.9rem;
  }
}
